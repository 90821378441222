import React from "react";

import { appURLs } from "@/app/app-urls.mjs";
import * as SOON from "@/game-cs2-marketing/constants/comingSoon.mjs";
import PROFILE_LINKS from "@/game-cs2-marketing/constants/profile-links.mjs";
import { GAME_SYMBOL_CS2 } from "@/game-csgo/definition-symbol.mjs";
import ComingSoon from "@/shared/ComingSoon.jsx";

const TITLE = [
  "common:navigation.cs2TrackerAndProfile",
  "CS2 Tracker and Profile",
];
const DESCRIPTION = [
  "",
  "Unlock your full potential in CS2 with our comprehensive Player Stats Tracker. Analyze detailed metrics, compare your performance with others, review matches with our replay tool, and get tailored insights to level up your game!",
];

function Profile() {
  return (
    <ComingSoon
      gameSymbol={GAME_SYMBOL_CS2}
      pageTitle={TITLE}
      pageLinks={PROFILE_LINKS}
      splashImage={`${appURLs.CDN}/blitz/cs2/comingSoon/cs2-soon-profile-weapons.webp`}
      splashTitle={SOON.TITLE}
      splashDecription={SOON.DESCRIPTION}
      footerTitle={SOON.FOOTER_TITLE}
      footerDescription={SOON.FOOTER_DESC}
    />
  );
}

export function meta() {
  return {
    title: TITLE,
    description: DESCRIPTION,
    subtitle: true,
  };
}

export default Profile;
